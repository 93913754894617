import React from "react"
import i18next from "i18next"
import * as ReactI18next from "react-i18next"
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'

export const AlternateLinksContext = React.createContext([])

export const wrapWithI18nProvider = params => {
  const { element, props } = params
  const { pageContext } = props
  const { language, i18nResources, alternateLinks } = pageContext
  const i18n = i18next
    .createInstance({
      lng: language,
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: i18nResources,
    })
    .use(ReactI18next.initReactI18next)
  // noinspection JSIgnoredPromiseFromCall
  i18n.init()
  return (
    <ReactI18next.I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider
        value={pageContext && alternateLinks}
      >
        {
          <Helmet htmlAttributes={{ lang: language }}>
            {pageContext &&
              alternateLinks &&
              alternateLinks.map(link => (
                <link
                  key={link.path}
                  rel="alternate"
                  hrefLang={link.language}
                  href={link.path}
                />
              ))}
          </Helmet>
        }
        {element}
      </AlternateLinksContext.Provider>
    </ReactI18next.I18nextProvider>
  )
}

wrapWithI18nProvider.propTypes = {
  pageContext: PropTypes.object,
  language: PropTypes.string,
  i18nResources: PropTypes.object,
  alternateLinks: PropTypes.array,
}